import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { PhoneInputField, Radio, Select, TextField } from "components/UI";
import { GOOGLE_API_KEY } from "config";
import { byCountry } from "country-code-lookup";
import { ADDRESSES, useMembersStore } from "features/members";
import { useEffect, useMemo } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  useCities,
  useCountries,
  useRegions,
  useSubcities,
  useWoredas,
  useZones
} from "../../api";

export const AddressSection = ({
  register,
  errors,
  watch,
  control,
  setValue,
  isEditMode
}) => {
  const fullMemberData = useMembersStore(state => state.fullMemberData);
  const { t } = useTranslation();

  const countriesQuery = useCountries();
  const regionsQuery = useRegions({
    countryId: watch("current_country_id"),
    config: {
      enabled: !!watch("current_country_id")
    }
  });
  const zonesQuery = useZones({
    regionId: watch("current_region_id"),
    config: {
      enabled: !!watch("current_region_id")
    }
  });
  const citiesQuery = useCities({
    zoneId: watch("current_zone_id"),
    config: {
      enabled: !!watch("current_zone_id")
    }
  });
  const subcitiesQuery = useSubcities({
    cityId: watch("current_city_id"),
    config: {
      enabled: !!watch("current_city_id")
    }
  });
  const woredasQuery = useWoredas({
    subcityId: watch("current_sub_city_id"),
    config: {
      enabled: !!watch("current_sub_city_id")
    }
  });

  useEffect(() => {
    if (countriesQuery.data) {
      if (isEditMode) {
        setValue("current_country_id", fullMemberData?.current_country_id);
      } else {
        setValue("current_country_id", "66");
      }
    }
  }, [countriesQuery.data, isEditMode]);

  useEffect(() => {
    if (regionsQuery.data && isEditMode) {
      setValue("current_region_id", fullMemberData?.current_region_id);
    }
  }, [regionsQuery.data, isEditMode]);

  useEffect(() => {
    if (zonesQuery.data && isEditMode) {
      setValue("current_zone_id", fullMemberData?.current_zone_id);
    }
  }, [zonesQuery.data, isEditMode]);

  useEffect(() => {
    if (citiesQuery.data && isEditMode) {
      setValue("current_city_id", fullMemberData?.current_city_id);
    }
  }, [citiesQuery.data, isEditMode]);

  useEffect(() => {
    if (subcitiesQuery.data && isEditMode) {
      setValue("current_sub_city_id", fullMemberData?.current_sub_city_id);
    }
  }, [subcitiesQuery.data, isEditMode]);

  useEffect(() => {
    if (woredasQuery.data && isEditMode) {
      setValue("current_woreda_id", fullMemberData?.current_woreda_id);
    }
  }, [woredasQuery.data, isEditMode]);

  const newSocialLinkField = {
    link: ""
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "social_media_links"
  });

  const isOutsideEthiopia = watch("current_country_id") !== "66";

  const countryCode = useMemo(() => {
    if (!countriesQuery.data) return "et";

    const selectedCountryId = watch("current_country_id");
    const selectedCountry = countriesQuery.data.result.find(
      country => country.id === Number(selectedCountryId)
    );

    if (!selectedCountry) return "et";

    const countryInfo = byCountry(selectedCountry.name);
    return countryInfo?.iso2?.toLowerCase() || "et";
  }, [countriesQuery.data, watch("current_country_id")]);

  const { ref: placesRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: place => {
      setValue("latitude", place.geometry.location.lat());
      setValue("longitude", place.geometry.location.lng());
    },
    options: {
      types: ["establishment"],
      componentRestrictions: { country: isOutsideEthiopia ? countryCode : "et" }
    }
  });

  return (
    <Grid container columnSpacing={12}>
      <Grid item xs={3} alignSelf="center">
        <Typography variant="body1">{t("addMembers.step1.Address")}</Typography>
      </Grid>
      <Grid container item xs={9} spacing={2}>
        <Grid item xs={3}>
          <Stack height="100%" justifyContent="end">
            <Radio
              label={t("addMembers.step1.HasOwnPhone")}
              name={`has_own_phone`}
              buttons={[
                {
                  radioLabel: t("form.common.yes"),
                  value: "1",
                  id: "own_phone_yes"
                },
                {
                  radioLabel: t("form.common.no"),
                  value: "0",
                  id: "own_phone_no"
                }
              ]}
              row={true}
              register={register}
            />
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <PhoneInputField
                label={t("form.common.phone")}
                variant="filled"
                error={!!errors.phone?.message}
                helperText={errors.phone?.message}
                {...field}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("addMembers.step1.OfficeNumber")}
            name="office_number"
            error={!!errors.office_number?.message}
            helperText={errors.office_number?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t("form.common.email")}
            name="email"
            error={!!errors.email?.message}
            helperText={errors.email?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t("form.common.pobox")}
            name="po_box"
            error={!!errors.po_box?.message}
            helperText={errors.po_box?.message}
            register={register}
          />
        </Grid>
        <Grid item xs={12}>
          {fields.map((field, index) => (
            <Stack direction="row" alignItems="center" key={field.id} mb={2}>
              <TextField
                label={t("addMembers.step1.SocialMediaLink")}
                name={`social_media_links.${index}.link`}
                error={!!errors.social_media_links?.[index]?.link?.message}
                helperText={errors.social_media_links?.[index]?.link?.message}
                register={register}
              />
              {index !== 0 && (
                <IconButton
                  sx={{
                    alignSelf: "flex-end"
                  }}
                  onClick={() => remove(index)}
                >
                  <RemoveCircleOutline color="primary" />
                </IconButton>
              )}
            </Stack>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <IconButton onClick={() => append(newSocialLinkField)}>
            <AddCircleOutline color="white" />
          </IconButton>
        </Grid>
        <Grid item xs={3}>
          <Select
            isLabelBold={0}
            label={t("form.common.country")}
            name="current_country_id"
            options={
              countriesQuery.data?.result?.map(country => ({
                optionLabel: country?.name,
                value: country?.id
              })) || []
            }
            register={register}
            error={!!errors.current_country_id?.message}
            helperText={errors.current_country_id?.message}
            required
          />
        </Grid>
        {!isOutsideEthiopia && (
          <Grid item xs={3}>
            <Select
              isLabelBold={0}
              label={t("form.common.region")}
              name={`current_region_id`}
              options={
                regionsQuery.data?.result?.map(region => ({
                  optionLabel: region?.name,
                  value: region?.id
                })) || []
              }
              register={register}
              error={!!errors.current_region_id?.message}
              helperText={errors.current_region_id?.message}
              onChange={e => {
                setValue("current_region_id", e.target.value, {
                  shouldValidate: true
                });

                if (e.target.value === ADDRESSES.ADDIS_ABABA.REGION_ID) {
                  setValue("current_zone_id", ADDRESSES.ADDIS_ABABA.ZONE_ID, {
                    shouldValidate: true
                  });
                  setValue("current_city_id", ADDRESSES.ADDIS_ABABA.CITY_ID, {
                    shouldValidate: true
                  });
                } else {
                  setValue("current_zone_id", "", {
                    shouldValidate: true
                  });
                  setValue("current_city_id", "", {
                    shouldValidate: true
                  });
                }
              }}
            />
          </Grid>
        )}
        {isOutsideEthiopia && (
          <>
            <Grid item xs={3}>
              <TextField
                label="City"
                name="current_city"
                error={!!errors.current_city?.message}
                helperText={errors.current_city?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="State"
                name="current_state"
                error={!!errors.current_state?.message}
                helperText={errors.current_state?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Zip Code"
                name="current_zip_code"
                error={!!errors.current_zip_code?.message}
                helperText={errors.current_zip_code?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Street Address"
                name="current_street_address"
                error={!!errors.current_street_address?.message}
                helperText={errors.current_street_address?.message}
                register={register}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Building Number"
                name="current_building_number"
                error={!!errors.current_building_number?.message}
                helperText={errors.current_building_number?.message}
                register={register}
              />
            </Grid>
          </>
        )}
        {!isOutsideEthiopia &&
          String(watch("current_region_id")) !==
            ADDRESSES.ADDIS_ABABA.REGION_ID && (
            <>
              <Grid item xs={3}>
                <Select
                  isLabelBold={0}
                  label={t("form.common.zone")}
                  name={`current_zone_id`}
                  options={
                    zonesQuery.data?.result?.map(zone => ({
                      optionLabel: zone?.name,
                      value: zone?.id
                    })) || []
                  }
                  register={register}
                  error={!!errors.current_zone_id?.message}
                  helperText={errors.current_zone_id?.message}
                />
              </Grid>
              <Grid item xs={3}>
                <Select
                  isLabelBold={0}
                  label={t("form.common.city")}
                  name={`current_city_id`}
                  options={
                    citiesQuery.data?.result?.map(city => ({
                      optionLabel: city?.name,
                      value: city?.id
                    })) || []
                  }
                  register={register}
                  error={!!errors.current_city_id?.message}
                  helperText={errors.current_city_id?.message}
                />
              </Grid>
            </>
          )}
        {!isOutsideEthiopia && (
          <Grid item xs={3}>
            <Select
              isLabelBold={0}
              label={t("form.common.subcity")}
              name={`current_sub_city_id`}
              options={
                subcitiesQuery.data?.result?.map(subcity => ({
                  optionLabel: subcity?.name,
                  value: subcity?.id
                })) || []
              }
              register={register}
              error={!!errors.current_sub_city_id?.message}
              helperText={errors.current_sub_city_id?.message}
            />
          </Grid>
        )}
        {!isOutsideEthiopia && (
          <Grid item xs={3}>
            <Select
              isLabelBold={0}
              label={t("form.common.woreda")}
              name={`current_woreda_id`}
              options={
                woredasQuery.data?.result?.map(woreda => ({
                  optionLabel: woreda?.name,
                  value: woreda?.id
                })) || []
              }
              register={register}
              error={!!errors.current_woreda_id?.message}
              helperText={errors.current_woreda_id?.message}
            />
          </Grid>
        )}
        {!isOutsideEthiopia &&
          String(watch("current_region_id")) !==
            ADDRESSES.ADDIS_ABABA.REGION_ID && (
            <Grid item xs={3}>
              <TextField
                label={t("form.common.kebele")}
                name="current_kebele"
                error={!!errors.current_kebele?.message}
                helperText={errors.current_kebele?.message}
                register={register}
              />
            </Grid>
          )}
        {!isOutsideEthiopia && (
          <Grid item xs={3}>
            <TextField
              label={t("form.common.houseNumber")}
              name="house_number"
              error={!!errors.house_number?.message}
              helperText={errors.house_number?.message}
              register={register}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            label={t("form.common.specificLocation")}
            name="specific_location"
            error={!!errors.specific_location?.message}
            helperText={errors.specific_location?.message}
            register={register}
            ref={placesRef}
            placeholder=""
            required
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
